import { graphql, StaticQuery } from "gatsby"
import DownloadLink from "@components/downloadLink"
import React from "react"
import * as css from "./rodo.module.css"

const query = graphql`
  query RodoTemplateQuery {
    allStrapiRodo {
      edges {
        node {
          plik_rodo {
            ext
            id
            size
            url
            name
          }
        }
      }
    }
  }
`

function RodoPageTemplate() {
  return (
    <div>
      <h1>RODO</h1>
      <p>
        <StaticQuery
          query={query}
          render={data => (
            <DownloadLink data={data} strapiCollection="allStrapiRodo">
              Pobierz RODO
            </DownloadLink>
          )}
        />
      </p>
      <h3 className={css.center}>
        INFORMACJA DOTYCZĄCA PRZETWARZANIA DANYCH OSOBOWYCH
      </h3>
      <p className={`${css.center} ${css.p1}`}>
        w Zespole Szkolno - Przedszkolnym im. M. Kopernika w Nidku.
      </p>
      <p>
        W związku z przetwarzaniem Pani/Pana danych osobowych informujemy –
        zgodnie z art. 13 ust. 1 i ust. 2 Rozporządzenia Parlamentu
        Europejskiego i Rady (UE) 2016/679 z dnia 27.04.2016 r. w sprawie
        ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w
        sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy
        95/46/WE (ogólne rozporządzenie o ochronie danych) (Dz. Urz. UE L z
        04.05.2016 r., Nr 119, s. 1), zwanego dalej w skrócie „RODO”, iż:
      </p>
      <ol type="I" className={css.mainOl}>
        <li>
          <h4>Administrator danych.</h4>
          <p>
            Administratorem Pani/Pana danych osobowych jest Zespół Szkolno-
            Przedszkolny im. M. Kopernika w Nidku z siedzibą przy ul. św. Judy
            Tadeusza 2
          </p>
          <p>
            Tel. <a href="tel:338755811">33 875 58 11</a>
          </p>
          <p>
            e-mail:{" "}
            <a href="mailto:sekretariat@zspnidek.wieprz.pl">
              sekretariat@zspnidek.wieprz.pl
            </a>
          </p>
          <p>adres www: zspnidek.pl</p>
          <h4 className={css.inspector}>Inspektor ochrony danych.</h4>
          <p>
            Administrator wyznaczył Inspektora Ochrony Danych, z którym może się
            Pani/Pan skontaktować w sprawach związanych z ochroną danych
            osobowych, w następujący sposób:
          </p>
          <ol>
            <li>
              pod adresem poczty elektronicznej Magdaleny Buczak:{" "}
              <a href="mailto:iod@wieprz.pl">iod@wieprz.pl</a>
            </li>
            <li>pisemnie na adres siedziby Administratora.</li>
            <li>
              Pod numerem tel. <a href="tel:+48506391066">506 391 066</a>
            </li>
          </ol>
        </li>
        <li>
          <h4>Podstawa prawna i cele przetwarzania danych osobowych.</h4>
          <ol>
            <li>
              Przetwarzanie Pani/Pana danych odbywa się w związku z realizacją
              zadań własnych bądź zleconych przez Zespół Szkolno- Przedszkolny w
              Nidku określonych przepisami prawa, w szczególności w art. 6 – 10d
              ustawy o samorządzie gminnym, w celu realizacji przysługujących
              gminieuprawnień, bądź spełnienia przez gminęobowiązków określonych
              tymi przepisami prawa albo gdy jest to niezbędne do wykonania
              zadania realizowanego w interesie publicznym lub w ramach
              sprawowania władzy publicznej.
            </li>
            <li>
              Przetwarzanie może być również niezbędne w celu wykonania umowy,
              której Pani/Pan jest stroną lub do podjęcia działań, na Pani/Pana
              żądanie przed zawarciem umowy.
            </li>
            <li>
              W celu promocji szkoły na podstawie Twoje zgody ( podstawa z art.
              6 ust.1 lit. A RODO)
            </li>
            <li>
              W celu wypełnienia obowiązku ustawowego, na podstawie obowiązku
              Ustawy- Prawo oświatowe z dn. 14 grudnia 2016r. (Dz. U. z 2017r.,
              poz.59) oraz Ustawy o systemie oświaty z dnia 7 września 1991r. (
              Dz. U. z 2017r., poz. 2198) w celu realizacji statutowych, zadań
              dydaktycznych, opiekuńczych i wychowawczych w placówce, nie będą
              udostępnione innym odbiorcom ( podstawa z art. 6 ust. 1 lit. C
              RODO)
            </li>
            <li>
              Mogą również wystąpić przypadki w których zostanie Pani/Pan
              poproszona/y o wyrażenie zgody na przetwarzanie danych osobowych w
              określonym celu i zakresie.
            </li>
          </ol>
        </li>
        <li>
          <h4>Odbiorcy danych osobowych.</h4>
          <p>
            W związku z przetwarzaniem danych w celach, o których mowa w pkt II
            odbiorcami Pani/Pana danych osobowych mogą być organy władzy
            publicznej oraz podmioty wykonujące zadania publiczne lub działające
            na zlecenie organów władzy publicznej, w zakresie i w celach, które
            wynikają z przepisów powszechnie obowiązującego prawa oraz inne
            podmioty, które na podstawie stosownych umów podpisanych z
            Administratorem przetwarzają dane osobowe na jego polecenie.
          </p>
        </li>
        <li>
          <h4>Okres przechowywania danych osobowych.</h4>
          <ol>
            <li>
              Pani/Pana dane osobowe będą przechowywane jedynie w okresie
              niezbędnym do spełnienia celu, dla którego zostały zebrane lub w
              okresie wskazanym przepisami prawa.
            </li>
            <li>
              Po spełnieniu celu, dla którego Pani/Pana dane zostały zebrane,
              mogą one być przechowywane jedynie w celach archiwalnych, przez
              okres, który wyznaczony zostanie przede wszystkim na podstawie
              rozporządzenia Prezesa Rady Ministrów w sprawie instrukcji
              kancelaryjnej, jednolitych rzeczowych wykazów akt oraz instrukcji
              w sprawie organizacji i zakresu działania archiwów zakładowych,
              chyba że przepisy szczególne stanowią inaczej.
            </li>
          </ol>
        </li>
        <li>
          <h4>
            Prawa osób, których dane dotyczą, w tym dostępu do danych osobowych.
          </h4>
          <p>
            Na zasadach określonych przepisami RODO, posiada Pani/Pan prawo do
            żądania od administratora:
          </p>
          <ol>
            <li>dostępu do treści swoich danych osobowych,</li>
            <li>sprostowania (poprawiania) swoich danych osobowych,</li>
            <li>
              usunięcia swoich danych osobowych w zakresie danych przetwarzanych
              na podstawie wyrażonej przez Panią/Pana zgody,
            </li>
            <li>
              ograniczenia przetwarzania swoich danych osobowych w momencie gdy
              prawidłowość przetwarzania danych osobowych jest kwestionowana,
            </li>
            <li>
              przenoszenia swoich danych osobowych w zakresie danych
              przetwarzanych na podstawie wyrażonej przez Panią/Pana zgody,
            </li>
          </ol>
          <p>
            a ponadto, posiada Pani/Pan prawo do wniesienia sprzeciwu wobec
            przetwarzania Pani/Pana danych.
          </p>
        </li>
        <li>
          <h4>Prawo do wycofania zgody.</h4>
          <ol>
            <li>
              Tam, gdzie do przetwarzania danych osobowych konieczne jest
              wyrażenie zgody, zawsze ma Pani/Pan prawo nie wyrazić zgody, a w
              przypadku jej wcześniejszego wyrażenia, do wycofania zgody.
            </li>
            <li>
              Wycofanie zgody nie ma wpływu na zgodność z prawem przetwarzania
              Pani/Pana danych do momentu jej wycofania.
            </li>
          </ol>
        </li>
        <li>
          <h4>Prawo wniesienia skargi do organu nadzorczego.</h4>
          <p>
            Gdy uzna Pani/Pan, iż przetwarzanie Pani/Pana danych osobowych
            narusza przepisy o ochronie danych osobowych, przysługuje Pani/Panu
            prawo do wniesienia skargi do organu nadzorczego, którym jest Prezes
            Urzędu Ochrony Danych Osobowych.
          </p>
        </li>
        <li>
          <h4>
            Informacja o wymogu/dobrowolności podania danych oraz konsekwencjach
            niepodania danych osobowych.
          </h4>
          <ol>
            <li>
              <p>
                Podanie przez Panią/Pana danych osobowych może być wymogiem:
              </p>
              <ol className={css.nestedOl}>
                <li>ustawowym,</li>
                <li>umownym,</li>
                <li>warunkiem zawarcia umowy,</li>
              </ol>
              <p>do których podania będzie Pani/Pan zobowiązana/y.</p>
            </li>
            <li>
              W przypadku, gdy będzie istniał obowiązek ustawowy, a nie poda
              Pani/Pan swoich danych, nie będziemy mogli zrealizować zadania
              ustawowego, co może skutkować konsekwencjami przewidzianymi
              przepisami prawa.
            </li>
            <li>
              W przypadku, gdy będzie istniał wymóg umowny, a nie poda Pani/Pan
              swoich danych, nie będziemy mogli wykonać takiej umowy.
            </li>
            <li>
              W przypadku, kiedy podanie danych będzie warunkiem zawarcia umowy,
              a nie poda Pani/Pan swoich danych, nie będziemy mogli zawrzeć
              takiej umowy.
            </li>
          </ol>
        </li>
        <li>
          <h4>Zautomatyzowane podejmowanie decyzji, profilowanie.</h4>
          <p>
            Pani/Pana dane osobowe nie podlegają zautomatyzowanemu podejmowaniu
            decyzji, w tym profilowaniu.
          </p>
        </li>
      </ol>
    </div>
  )
}

export default RodoPageTemplate
