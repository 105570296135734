import Layout from "../../components/layout"
import React from "react"
import SEO from "../../components/seo"
import RodoPageTemplate from "@templates/RodoPageTemplate"

function RodoKindergarden() {
  return (
    <Layout menuType="kindergarden">
      <SEO title="RODO - ZSP Nidek" />
      <RodoPageTemplate />
    </Layout>
  )
}

export default RodoKindergarden
